import React from "react";
import  { Logo } from '../logo.svg';

const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

const Header = () => {
  return (
    <header>
      <div className='logo'>
        <img src="https://metaland.metaclub.biz/static/media/logo.c8d64be3.png" height="60px" width="60px"/>
      </div>
      <div className='episode'  >
        {/**
         * <p style={{fontSize: "20px", cursor: "pointer"}} onClick={()=>window.open("https://staking.metaclub.biz/")}>App</p>
         */}
      </div>
    </header>
  );
};

export default Header;
