import React, { useRef, useState } from 'react'
//R3F
import { Canvas, useFrame } from 'react-three-fiber'
// Deai - R3F
import { softShadows, MeshWobbleMaterial, OrbitControls, Text } from 'drei'
//Components
import Header from './components/header'
// Styles
import './App.scss'
// React Spring
import { useSpring, a } from 'react-spring/three'
import './lato.bold.ttf'
import { Logo } from './logo.png'
import { Flex, Box } from 'react-three-flex'

// soft Shadows
softShadows()

const SpinningMesh = ({ position, color, speed, args }) => {
  //ref to target the mesh
  const mesh = useRef()

  //useFrame allows us to re-render/update rotation on each frame
  useFrame(() => (mesh.current.rotation.x = mesh.current.rotation.y += 0.02))

  //Basic expand state
  const [expand, setExpand] = useState(false)
  // React spring expand animation
  const props = useSpring({
    scale: expand ? [1.4, 1.4, 1.4] : [1, 1, 1]
  })
  return (
    <a.mesh
      position={position}
      ref={mesh}
      onClick={() => setExpand(!expand)}
      scale={props.scale}
      castShadow
    >
      <boxBufferGeometry attach='geometry' args={args} />
      <MeshWobbleMaterial
        color={color}
        speed={speed}
        attach='material'
        factor={0.6}
      />
    </a.mesh>

    //Using Drei box if you want
    // <Box {...props} ref={mesh} castShadow>
    //   <MeshWobbleMaterial
    //     {...props}
    //     attach='material'
    //     factor={0.6}
    //     Speed={1}
    //   />
    // </Box>
  )
}

/**[START] BIG VIEW */
const Big = () => {
  return (
    <>
      <Header />
      {/* Our Scene & Camera is already built into our canvas */}
      <Canvas
        colorManagement
        shadowMap
        camera={{ position: [0, 0, 10], fov: 60 }}
      >
        {/* This light makes things look pretty */}
        <ambientLight intensity={0.3} />
        {/* Our main source of light, also casting our shadow */}
        <directionalLight
          castShadow
          position={[0, 10, 0]}
          intensity={1.5}
          shadow-mapSize-width={1024}
          shadow-mapSize-height={1024}
          shadow-camera-far={50}
          shadow-camera-left={-10}
          shadow-camera-right={10}
          shadow-camera-top={10}
          shadow-camera-bottom={-10}
        />
        {/* A light to help illumnate the spinning boxes */}
        <pointLight position={[-10, 0, -20]} intensity={0.5} />
        <pointLight position={[0, -10, 0]} intensity={1.5} />
        <group>
          {/* This mesh is the plane (The floor) */}
          <mesh
            rotation={[-Math.PI / 2, 0, 0]}
            position={[0, -3, 0]}
            receiveShadow
          >
            <planeBufferGeometry attach='geometry' args={[150, 150]} />
            <shadowMaterial attach='material' opacity={0.3} />
          </mesh>
          <SpinningMesh
            position={[0, 2, 0]}
            color='orange'
            args={[3, 2, 1]}
            speed={8}
          />
        </group>
        {/* Allows us to move the canvas around for different prespectives */}
        {/** <OrbitControls /> */}
        <Text
          color='black'
          anchorX='center'
          anchorY='middle'
          fontSize={2}
          anchorX='center'
          font='https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff'
          anchorY='middle'
          position={[0, -2, 0]}
        >
          META LAND YIELD
        </Text>
      </Canvas>
      <Canvas colorManagement shadowMap camera={{ position: [0, 0, 10] }}>
        <Flex size={[300, 300, 300]} flexDirection='column'>
          <Box>
            <Text
              color='black'
              fontSize={0.6}
              maxWidth={200}
              lineHeight={1}
              letterSpacing={0.02}
              textAlign={'left'}
              font='https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff'
            >
              Investing in the metaverse is tough, expensive, and time consuming
            </Text>
          </Box>
          <Box>
            <Text
              //ref={ref}
              color={'blue'}
              fontSize={0.8}
              maxWidth={200}
              lineHeight={1}
              letterSpacing={0.02}
              textAlign={'left'}
              font='https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff'
              anchorX='center'
              anchorY='middle'
              outlineColor='black'
            >
              We make it simple
            </Text>
          </Box>
          <Box> </Box>
          <Box>
            <Text
              color='black'
              fontSize={0.6}
              maxWidth={200}
              lineHeight={1}
              letterSpacing={0.02}
              textAlign={'left'}
              font='https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff'
            >
              In just 3 steps, everyone can invest in the Metaverse.
            </Text>
          </Box>
        </Flex>
      </Canvas>
      <Canvas colorManagement shadowMap camera={{ position: [0, 0, 10] }}>
        <Flex size={[300, 300, 300]} flexDirection='column'>
          <Box>
            <pointLight position={[0, -10, 0]} intensity={1.5} />
            <SpinningMesh
              position={[0, 2, 0]}
              color='green'
              args={[3, 1, 1]}
              speed={1.5}
            />
            <SpinningMesh
              position={[0, 2, 0]}
              color='red'
              args={[1, 1, 1]}
              speed={1.5}
            />
            <SpinningMesh
              position={[0, 2, 0]}
              color='blue'
              args={[1, 1, 1]}
              speed={1.5}
            />
          </Box>

          <Box>
            <Text
              color='black'
              fontSize={0.6}
              maxWidth={200}
              lineHeight={1}
              letterSpacing={0.02}
              textAlign={'left'}
              font='https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff'
            >
              1. Buy $MLY
            </Text>
          </Box>

          <Box>
            <Text
              color='black'
              fontSize={0.6}
              maxWidth={200}
              lineHeight={1}
              letterSpacing={0.02}
              textAlign={'left'}
              font='https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff'
            >
              2. Stake your $MLY
            </Text>
          </Box>

          <Box>
            <Text
              color='black'
              fontSize={0.6}
              maxWidth={200}
              lineHeight={1}
              letterSpacing={0.02}
              textAlign={'left'}
              font='https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff'
            >
              3. Enjoy rewards across all metaverse assets
            </Text>
          </Box>
        </Flex>
      </Canvas>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '10%'
        }}
      >
        <div className='card'>
          <p
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() =>
              window.open(
                'https://bscscan.com/address/0x553af511a05fe87476e49374983eeca0d63bca90'
              )
            }
          >
            Contract address: 0x553af511a05fe87476e49374983eeca0d63bca90
          </p>
          <p>Total Supply: 1000000000 </p>
          <p
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => window.open('https://staking.metaclub.biz/')}
          >
            Go to staking Dashboard{' '}
          </p>
          <p
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => window.open('https://twitter.com/MetaClub1')}
          >
            Twitter{' '}
          </p>
          <p
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => window.open('https://mly-dashboard.metaclub.biz/')}
          >
            DASHBOARD{' '}
          </p>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'center',
          justifyContent: 'center'
        }}
      >
        <p
          style={{
            fontSize: '20px',
            fontWeight: 'bold',
            textDecoration: 'underline'
          }}
        >
          Metaverse Investments
        </p>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'center',
          justifyContent: 'center'
        }}
      >
        <div class='grid-container'>
          <div
            class='grid-item'
            onClick={() => window.open('https://magiceden.io/u/metalandyield')}
          >
            <div>
              <p style={{ fontSize: '20px', fontWeight: 'bold' }}>
                Solana NFTs
              </p>
            </div>
          </div>
          <div class='grid-item'>
            <div>
              <p style={{ fontSize: '20px', fontWeight: 'bold' }}>BSC NFTs</p>
            </div>
          </div>
          <div
            class='grid-item'
            onClick={() => window.open('https://opensea.io/MetaLandYield')}
          >
            <div>
              <p style={{ fontSize: '20px', fontWeight: 'bold' }}>ETH NFTs</p>
            </div>
          </div>
          <div
            class='grid-item'
            onClick={() =>
              window.open(
                'https://paras.id/67b2dae28d3f01ce0cf2cd2c0214b218b4569c0d495da89ce712c32c4764966e/collectibles'
              )
            }
          >
            <div>
              <p style={{ fontSize: '20px', fontWeight: 'bold' }}>NEAR NFTs</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
/**[END] BIG VIEW */

/**[START] MID VIEW */
const Mid = () => {
  return (
    <>
      <Header />
      {/* Our Scene & Camera is already built into our canvas */}
      <Canvas
        colorManagement
        shadowMap
        camera={{ position: [0, 0, 10], fov: 60 }}
      >
        {/* This light makes things look pretty */}
        <ambientLight intensity={0.3} />
        {/* Our main source of light, also casting our shadow */}
        <directionalLight
          castShadow
          position={[0, 10, 0]}
          intensity={1.5}
          shadow-mapSize-width={1024}
          shadow-mapSize-height={1024}
          shadow-camera-far={50}
          shadow-camera-left={-10}
          shadow-camera-right={10}
          shadow-camera-top={10}
          shadow-camera-bottom={-10}
        />
        {/* A light to help illumnate the spinning boxes */}
        <pointLight position={[-10, 0, -20]} intensity={0.5} />
        <pointLight position={[0, -10, 0]} intensity={1.5} />
        <group>
          {/* This mesh is the plane (The floor) */}
          <mesh
            rotation={[-Math.PI / 2, 0, 0]}
            position={[0, -3, 0]}
            receiveShadow
          >
            <planeBufferGeometry attach='geometry' args={[150, 150]} />
            <shadowMaterial attach='material' opacity={0.3} />
          </mesh>
          <SpinningMesh
            position={[0, 2, 0]}
            color='orange'
            args={[3, 2, 1]}
            speed={8}
          />
        </group>
        {/* Allows us to move the canvas around for different prespectives */}
        {/** <OrbitControls /> */}
        <Text
          color='black'
          anchorX='center'
          anchorY='middle'
          fontSize={1}
          anchorX='center'
          font='https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff'
          anchorY='middle'
          position={[0, -2, 0]}
        >
          META LAND YIELD
        </Text>
      </Canvas>
      <Canvas colorManagement shadowMap camera={{ position: [0, 0, 10] }}>
        <Flex size={[300, 300, 300]} flexDirection='column'>
          <Box>
            <Text
              color='black'
              fontSize={0.4}
              maxWidth={200}
              lineHeight={1}
              letterSpacing={0.02}
              textAlign={'left'}
              font='https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff'
            >
              Investing in the metaverse is tough, expensive, and time consuming
            </Text>
          </Box>
          <Box>
            <Text
              //ref={ref}
              color={'blue'}
              fontSize={0.8}
              maxWidth={200}
              lineHeight={1}
              letterSpacing={0.02}
              textAlign={'left'}
              font='https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff'
              anchorX='center'
              anchorY='middle'
              outlineColor='black'
            >
              We make it simple
            </Text>
          </Box>
          <Box> </Box>
          <Box>
            <Text
              color='black'
              fontSize={0.4}
              maxWidth={200}
              lineHeight={1}
              letterSpacing={0.02}
              textAlign={'left'}
              font='https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff'
            >
              In just 3 steps, everyone can invest in the Metaverse.
            </Text>
          </Box>
        </Flex>
      </Canvas>
      <Canvas colorManagement shadowMap camera={{ position: [0, 0, 10] }}>
        <Flex size={[300, 300, 300]} flexDirection='column'>
          <Box>
            <pointLight position={[0, -10, 0]} intensity={1.5} />
            <SpinningMesh
              position={[0, 2, 0]}
              color='green'
              args={[3, 1, 1]}
              speed={1.5}
            />
            <SpinningMesh
              position={[0, 2, 0]}
              color='red'
              args={[1, 1, 1]}
              speed={1.5}
            />
            <SpinningMesh
              position={[0, 2, 0]}
              color='blue'
              args={[1, 1, 1]}
              speed={1.5}
            />
          </Box>

          <Box>
            <Text
              color='black'
              fontSize={0.6}
              maxWidth={200}
              lineHeight={1}
              letterSpacing={0.02}
              textAlign={'left'}
              font='https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff'
            >
              1. Buy $MLY
            </Text>
          </Box>

          <Box>
            <Text
              color='black'
              fontSize={0.6}
              maxWidth={200}
              lineHeight={1}
              letterSpacing={0.02}
              textAlign={'left'}
              font='https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff'
            >
              2. Stake your $MLY
            </Text>
          </Box>

          <Box>
            <Text
              color='black'
              fontSize={0.6}
              maxWidth={200}
              lineHeight={1}
              letterSpacing={0.02}
              textAlign={'left'}
              font='https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff'
            >
              3. Enjoy rewards across all metaverse assets
            </Text>
          </Box>
        </Flex>
      </Canvas>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '10%'
        }}
      >
        <div className='card'>
          <p
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() =>
              window.open(
                'https://bscscan.com/address/0x553af511a05fe87476e49374983eeca0d63bca90'
              )
            }
          >
            Contract address: 0x553af511a05fe87476e49374983eeca0d63bca90
          </p>
          <p>Total Supply: 1000000000 </p>
          <p
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => window.open('https://staking.metaclub.biz/')}
          >
            Go to staking Dashboard{' '}
          </p>
          <p
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => window.open('https://twitter.com/MetaClub1')}
          >
            Twitter{' '}
          </p>
          <p
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => window.open('https://mly-dashboard.metaclub.biz/')}
          >
            DASHBOARD{' '}
          </p>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'center',
          justifyContent: 'center'
        }}
      >
        <p
          style={{
            fontSize: '20px',
            fontWeight: 'bold',
            textDecoration: 'underline'
          }}
        >
          Metaverse Investments
        </p>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'center',
          justifyContent: 'center'
        }}
      >
        <div class='grid-container'>
          <div
            class='grid-item'
            onClick={() => window.open('https://magiceden.io/u/metalandyield')}
          >
            <div>
              <p style={{ fontSize: '20px', fontWeight: 'bold' }}>
                Solana NFTs
              </p>
            </div>
          </div>
          <div class='grid-item'>
            <div>
              <p style={{ fontSize: '20px', fontWeight: 'bold' }}>BSC NFTs</p>
            </div>
          </div>
          <div
            class='grid-item'
            onClick={() => window.open('https://opensea.io/MetaLandYield')}
          >
            <div>
              <p style={{ fontSize: '20px', fontWeight: 'bold' }}>ETH NFTs</p>
            </div>
          </div>
          <div
            class='grid-item'
            onClick={() =>
              window.open(
                'https://paras.id/67b2dae28d3f01ce0cf2cd2c0214b218b4569c0d495da89ce712c32c4764966e/collectibles'
              )
            }
          >
            <div>
              <p style={{ fontSize: '20px', fontWeight: 'bold' }}>NEAR NFTs</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
/**[END] MID VIEW */

/**[START] SMOL VIEW */
const Smol = () => {
  return (
    <>
      <Header />
      {/* Our Scene & Camera is already built into our canvas */}
      <Canvas
        colorManagement
        shadowMap
        camera={{ position: [0, 0, 10], fov: 60 }}
      >
        {/* This light makes things look pretty */}
        <ambientLight intensity={0.3} />
        {/* Our main source of light, also casting our shadow */}
        <directionalLight
          castShadow
          position={[0, 10, 0]}
          intensity={1.5}
          shadow-mapSize-width={1024}
          shadow-mapSize-height={1024}
          shadow-camera-far={50}
          shadow-camera-left={-10}
          shadow-camera-right={10}
          shadow-camera-top={10}
          shadow-camera-bottom={-10}
        />
        {/* A light to help illumnate the spinning boxes */}
        <pointLight position={[-10, 0, -20]} intensity={0.5} />
        <pointLight position={[0, -10, 0]} intensity={1.5} />
        <group>
          {/* This mesh is the plane (The floor) */}
          <mesh
            rotation={[-Math.PI / 2, 0, 0]}
            position={[0, -3, 0]}
            receiveShadow
          >
            <planeBufferGeometry attach='geometry' args={[150, 150]} />
            <shadowMaterial attach='material' opacity={0.3} />
          </mesh>
          <SpinningMesh
            position={[0, 2, 0]}
            color='orange'
            args={[3, 2, 1]}
            speed={8}
          />
        </group>
        {/* Allows us to move the canvas around for different prespectives */}
        {/** <OrbitControls /> */}
        <Text
          color='black'
          anchorX='center'
          anchorY='middle'
          fontSize={0.5}
          anchorX='center'
          font='https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff'
          anchorY='middle'
          position={[0, -2, 0]}
        >
          META LAND YIELD
        </Text>
      </Canvas>
      <Canvas colorManagement shadowMap camera={{ position: [0, 0, 10] }}>
        <Flex size={[300, 300, 300]} flexDirection='column'>
          <Box>
            <Text
              color='black'
              fontSize={0.4}
              maxWidth={200}
              lineHeight={1}
              letterSpacing={0.02}
              textAlign={'left'}
              font='https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff'
            >
              Investing in the metaverse is tough,
            </Text>
          </Box>
          <Box>
            <Text
              color='black'
              fontSize={0.4}
              maxWidth={200}
              lineHeight={1}
              letterSpacing={0.02}
              textAlign={'left'}
              font='https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff'
            >
              expensive, and time consuming
            </Text>
          </Box>
          <Box>
            <Text
              //ref={ref}
              color={'blue'}
              fontSize={0.4}
              maxWidth={200}
              lineHeight={1}
              letterSpacing={0.02}
              textAlign={'left'}
              font='https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff'
              anchorX='center'
              anchorY='middle'
              outlineColor='black'
            >
              We make it simple
            </Text>
          </Box>
          <Box> </Box>
          <Box>
            <Text
              color='black'
              fontSize={0.4}
              maxWidth={200}
              lineHeight={1}
              letterSpacing={0.02}
              textAlign={'left'}
              font='https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff'
            >
              In just 3 steps,
            </Text>
          </Box>
          <Box>
            <Text
              color='black'
              fontSize={0.4}
              maxWidth={200}
              lineHeight={1}
              letterSpacing={0.02}
              textAlign={'left'}
              font='https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff'
            >
              everyone can invest
            </Text>
          </Box>
          <Box>
            <Text
              color='black'
              fontSize={0.4}
              maxWidth={200}
              lineHeight={1}
              letterSpacing={0.02}
              textAlign={'left'}
              font='https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff'
            >
              in the Metaverse.
            </Text>
          </Box>
        </Flex>
      </Canvas>
      <Canvas colorManagement shadowMap camera={{ position: [0, 0, 10] }}>
        <Flex size={[300, 300, 300]} flexDirection='column'>
          <Box>
            <pointLight position={[0, -10, 0]} intensity={1.5} />
            <SpinningMesh
              position={[0, 2, 0]}
              color='green'
              args={[3, 1, 1]}
              speed={1.5}
            />
            <SpinningMesh
              position={[0, 2, 0]}
              color='red'
              args={[1, 1, 1]}
              speed={1.5}
            />
            <SpinningMesh
              position={[0, 2, 0]}
              color='blue'
              args={[1, 1, 1]}
              speed={1.5}
            />
          </Box>

          <Box>
            <Text
              color='black'
              fontSize={0.4}
              maxWidth={200}
              lineHeight={1}
              letterSpacing={0.02}
              textAlign={'left'}
              font='https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff'
            >
              1. Buy $MLY
            </Text>
          </Box>

          <Box>
            <Text
              color='black'
              fontSize={0.4}
              maxWidth={200}
              lineHeight={1}
              letterSpacing={0.02}
              textAlign={'left'}
              font='https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff'
            >
              2. Stake your $MLY
            </Text>
          </Box>

          <Box>
            <Text
              color='black'
              fontSize={0.4}
              maxWidth={200}
              lineHeight={1}
              letterSpacing={0.02}
              textAlign={'left'}
              font='https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff'
            >
              3. Enjoy rewards across
            </Text>
          </Box>

          <Box>
            <Text
              color='black'
              fontSize={0.4}
              maxWidth={200}
              lineHeight={1}
              letterSpacing={0.02}
              textAlign={'left'}
              font='https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff'
            >
              all metaverse assets
            </Text>
          </Box>
        </Flex>
      </Canvas>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '10%'
        }}
      >

        <div className='card'>
          <p
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() =>
              window.open(
                'https://bscscan.com/address/0x553af511a05fe87476e49374983eeca0d63bca90'
              )
            }
          >
            Contract address
          </p>
          <p>Total Supply: 1000000000 </p>
          <p
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => window.open('https://staking.metaclub.biz/')}
          >
            Go to staking Dashboard{' '}
          </p>
          <p
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => window.open('https://twitter.com/MetaClub1')}
          >
            Twitter{' '}
          </p>
          <p
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => window.open('https://mly-dashboard.metaclub.biz/')}
          >
            DASHBOARD{' '}
          </p>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'center',
          justifyContent: 'center'
        }}
      >
        <p
          style={{
            fontSize: '20px',
            fontWeight: 'bold',
            textDecoration: 'underline'
          }}
        >
          Metaverse Investments
        </p>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'center',
          justifyContent: 'center'
        }}
      >
        <div class='grid-container'>
          <div
            class='grid-item'
            onClick={() => window.open('https://magiceden.io/u/metalandyield')}
          >
            <div>
              <p style={{ fontSize: '20px', fontWeight: 'bold' }}>
                Solana NFTs
              </p>
            </div>
          </div>
          <div class='grid-item'>
            <div>
              <p style={{ fontSize: '20px', fontWeight: 'bold' }}>BSC NFTs</p>
            </div>
          </div>
          <div
            class='grid-item'
            onClick={() => window.open('https://opensea.io/MetaLandYield')}
          >
            <div>
              <p style={{ fontSize: '20px', fontWeight: 'bold' }}>ETH NFTs</p>
            </div>
          </div>
          <div
            class='grid-item'
            onClick={() =>
              window.open(
                'https://paras.id/67b2dae28d3f01ce0cf2cd2c0214b218b4569c0d495da89ce712c32c4764966e/collectibles'
              )
            }
          >
            <div>
              <p style={{ fontSize: '20px', fontWeight: 'bold' }}>NEAR NFTs</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
/**[END] SMOL VIEW */

const App = () => {
  // Declare a new state variable with the "useState" Hook
  const [width, setWidth] = React.useState(window.innerWidth)
  const breakpoint1 = 1200
  const breakpoint2 = 650

  React.useEffect(() => {
    /* Inside of a "useEffect" hook add an event listener that updates
       the "width" state variable when the window size changes */
    window.addEventListener('resize', () => setWidth(window.innerWidth))

    /* passing an empty array as the dependencies of the effect will cause this
       effect to only run when the component mounts, and not each time it updates.
       We only want the listener to be added once */
  }, [])

  return width < breakpoint1 ? (
    width < breakpoint2 ? (
      <Smol />
    ) : (
      <Mid />
    )
  ) : (
    <Big />
  )
}

export default App
